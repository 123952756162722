import React from 'react';
import { Else, If, Then, When } from 'react-if';
import { Spinner } from 'react-bootstrap';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { consumersPerformLoginCreate } from '../state/actions';
import Errors from 'framework/components/Errors';

const ConsumerWelcomeComponent = (props) => {
  let { userData } = props;

  let { loading, errorPerformLogin } = useSelector((state) => state.consumer);

  const onClickContinue = () => {
    consumersPerformLoginCreate({ username: userData.username });
  };

  return (
    <div className="cc general-wrapper welcome-wrapper">
      <When condition={errorPerformLogin?.non_field_errors}>
        <Errors data={errorPerformLogin?.non_field_errors} />
      </When>
      <div className="phoneNumber-form-info mt-3 text-right">
        <h4 className="font-weight-bold heading-title">
          سلام
          {userData.firstName && userData.lastName
            ? ` آقا/خانم ${userData.firstName} ${userData.lastName}!`
            : `!`}
        </h4>
        <p>
          شما در حال لاگین با نام کاربری{' '}
          <span className={'font-weight-bold'}>{userData.username}</span> هستید
          برای اتمام فرآیند بر روی{' '}
          <span className={'font-weight-bold'}>ادامه </span> کلیک کنید
        </p>
      </div>
      <button
        className={clsx({
          'login-button': true,
          'login-button-disabled': loading,
          'w-100': true,
          'border-0': true,
          'mt-1': true,
          'font-weight-bold': true,
        })}
        variant="primary"
        onClick={onClickContinue}
        disabled={loading}
      >
        <If condition={!loading}>
          <Then>
            <span>ادامه</span>
          </Then>
          <Else>
            <Spinner animation="border" variant="light" />
          </Else>
        </If>
      </button>
    </div>
  );
};

export default ConsumerWelcomeComponent;
