import { CONSUMER_LOGIN_TYPES } from './reducers';
import { request } from 'framework/helpers/request';
import { dispatch } from 'framework/state/store';

export const consumersSendCodeCreate = async (data) => {
  try {
    dispatch({ type: CONSUMER_LOGIN_TYPES.setLoadingLogin });

    await request.post('v1/consumers/registration/send-code/', data);

    dispatch({ type: CONSUMER_LOGIN_TYPES.getCode });
  } catch (e) {
    dispatch({
      type: CONSUMER_LOGIN_TYPES.getCodeError,
      payload: e?.response?.data,
    });
  }
};

export const consumersAccountsListCreate = async (data) => {
  try {
    dispatch({ type: CONSUMER_LOGIN_TYPES.setLoadingLogin });

    const res = await request.post('v1/consumers/registration/accounts/', {
      promise_token: data,
    });

    dispatch({
      type: CONSUMER_LOGIN_TYPES.getAllAccounts,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: CONSUMER_LOGIN_TYPES.getAllAccountsError,
      payload: e?.response?.data,
    });
  }
};

export const consumersFinalCreate = async (data) => {
  try {
    dispatch({ type: CONSUMER_LOGIN_TYPES.setLoadingLogin });

    let res = await request.post('v1/consumers/registration/final/', data);

    dispatch({ type: CONSUMER_LOGIN_TYPES.getAccount, payload: res.data });
  } catch (e) {
    dispatch({
      type: CONSUMER_LOGIN_TYPES.getAccountError,
      payload: e?.response?.data,
    });
  }
};

export const consumersRegisterCreate = async (data) => {
  try {
    dispatch({ type: CONSUMER_LOGIN_TYPES.setLoadingLogin });

    let res = await request.post('v1/consumers/registration/register/', data);

    dispatch({
      type: CONSUMER_LOGIN_TYPES.createAccount,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: CONSUMER_LOGIN_TYPES.createAccountError,
      payload: e?.response?.data,
    });
  }
};

export const consumersVerifyCodeCreate = async (data) => {
  try {
    dispatch({ type: CONSUMER_LOGIN_TYPES.setLoadingLogin });

    let res = await request.post(
      'v1/consumers/registration/verify-code/',
      data
    );
    dispatch({
      type: CONSUMER_LOGIN_TYPES.setCodeConfirmed,
    });
    dispatch({
      type: CONSUMER_LOGIN_TYPES.setPhoneNumberToken,
      payload: res.data.verified_phone_number_token,
    });
    dispatch({
      type: CONSUMER_LOGIN_TYPES.getAllAccounts,
      payload: res.data.accounts,
    });
  } catch (e) {
    dispatch({
      type: CONSUMER_LOGIN_TYPES.sendCodeError,
      payload: e?.response?.data,
    });
  }
};

export const consumersEditPhoneNumber = async () => {
  dispatch({ type: CONSUMER_LOGIN_TYPES.editPhoneNumber });
};

export const consumersSetPhoneNumber = async (phoneNumber) => {
  dispatch({ type: CONSUMER_LOGIN_TYPES.setPhoneNumber, payload: phoneNumber });
};

export const consumersPerformLoginCreate = async (data) => {
  try {
    dispatch({ type: CONSUMER_LOGIN_TYPES.setLoadingLogin });

    let res = await request.post('v1/consumers/login/perform-login/', data, {
      withCredentials: true,
    });

    dispatch({
      type: CONSUMER_LOGIN_TYPES.performLogin,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: CONSUMER_LOGIN_TYPES.errorPerformLogin,
      payload: e?.response?.data,
    });
  }
};
