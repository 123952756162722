import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Form, Spinner } from 'react-bootstrap';
import { Else, If, Then, When } from 'react-if';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Timer from 'react-compound-timer';
import { digitsArToEn, digitsFaToEn } from '@persian-tools/persian-tools';
import { camelCase } from 'change-case';
import Input from 'framework/components/Input';
import Errors from 'framework/components/Errors';
import {
  editPhoneNumber,
  loginConfirmCodeCreate,
  loginSendCodeCreate,
} from '../state/actions';

const UserConfirmCode = (props) => {
  let { phoneNumber, onPrev, onNext, onShowCreateAccount } = props;

  let [showTimer, setShowTimer] = useState(true);

  let { isCodeSent, loading, allAccounts, errorAllAccounts } = useSelector(
    (state) => state.login
  );

  let { handleSubmit, errors, control, setValue, setError } = useForm({
    mode: 'onSubmit',
  });

  let handleChange = (e) =>
    setValue('code', digitsFaToEn(digitsArToEn(e.target.value)));

  function onResendCode() {
    setShowTimer(true);

    let data = { phone_number: phoneNumber };
    loginSendCodeCreate(data);
  }

  function Label() {
    return (
      <div className="text-right">
        <h4 className="font-weight-bold">لطفا رمز یکبار مصرف را وارد کنید</h4>
        <p className="cc--phone_number">
          رمز یکبار مصرف به شماره موبایل {phoneNumber} ارسال شد{' '}
        </p>
      </div>
    );
  }

  function onSubmit(data) {
    let newData = {
      phone_number: phoneNumber,
      ...data,
    };
    loginConfirmCodeCreate(newData);
  }

  let handleBackBtnClick = () => editPhoneNumber();

  useEffect(() => {
    if (!isCodeSent) {
      onPrev();
    }
  }, [isCodeSent, onPrev]);

  useEffect(() => {
    if (allAccounts) {
      if (allAccounts?.length === 0) {
        onShowCreateAccount();
      } else {
        onNext();
      }
    }
  }, [allAccounts, onNext, onShowCreateAccount]);

  useEffect(() => {
    if (errorAllAccounts && typeof errorGetCode !== 'string') {
      Object.keys(errorAllAccounts).forEach((item) => {
        if (item !== 'non_field_errors' && item !== 'detail') {
          setError(camelCase(item), {
            type: 'manual',
            message: errorAllAccounts[item][0],
          });
        }
      });
    }
  }, [errorAllAccounts, setError]);

  //confirm code => cc (class name)
  return (
    <div className="cc general-wrapper">
      <Form className="cc--form" onSubmit={handleSubmit(onSubmit)}>
        <When condition={errorAllAccounts?.non_field_errors}>
          <Errors data={errorAllAccounts?.non_field_errors} />
        </When>
        <Label />
        <Input
          name="code"
          classInput="cc--input"
          classLabel={'cc--label'}
          type="text"
          maxlength={'6'}
          placeholder="کد تأیید"
          control={control}
          rules={{ required: 'لطفا رمز وارد کنید', minLength: 6 }}
          error={errors.username?.message}
          onChange={handleChange}
        />
        <button
          className={clsx({
            'login-button': true,
            'login-button-disabled': loading,
            'w-100': true,
            'border-0': true,
            'mt-1': true,
            'font-weight-bold': true,
          })}
          // variant='primary'
          type="submit"
          disabled={loading}
        >
          <If condition={!loading}>
            <Then>
              <span>تایید</span>
            </Then>
            <Else>
              <Spinner animation="border" variant="light" />
            </Else>
          </If>
        </button>
      </Form>
      <div className="cc--timer-wrapper d-flex justify-content-between">
        <div className="d-flex text-info">
          <span
            className="back-icon text-info cc--back"
            onClick={handleBackBtnClick}
          >
            <FontAwesomeIcon icon={faChevronRight} className={'ml-1'} />
            اصلاح شماره موبایل
          </span>
        </div>
        {showTimer ? (
          <Timer
            initialTime={180000}
            direction="backward"
            formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
          >
            {({ getTimerState }) => {
              if (getTimerState() === 'STOPPED') setShowTimer(false);
              return (
                <div className="cc--timer">
                  <span className="cc--timer-text">
                    زمان باقی مانده تا ارسال مجدد رمز
                  </span>
                  <span className="cc--timer-number">
                    <Timer.Minutes />
                    :
                    <Timer.Seconds />
                  </span>
                </div>
              );
            }}
          </Timer>
        ) : (
          <span className="resend-code" onClick={onResendCode}>
            <span className={'resend-code--password'}>
              رمز را دریافت نکرده اید؟
            </span>
            <span className={'text-info resend-code--resend'}>
              ارسال مجدد رمز
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

export default UserConfirmCode;
