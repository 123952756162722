export const CONSUMER_LOGIN_TYPES = {
  setLoadingLogin: 'consumerSetLoadingLogin',
  getCode: 'consumerGetCode',
  getCodeError: 'consumerGetCodeError',
  editPhoneNumber: 'consumerEditPhoneNumber',
  getAllAccounts: 'consumerGetAllAccounts',
  getAllAccountsError: 'consumerGetAllAccountsError',
  getAccount: 'consumerGetAccount',
  getAccountError: 'consumerGetAccountError',
  errorLogin: 'consumerErrorLogin',
  createAccount: 'consumerCreateAccount',
  createAccountError: 'consumerCreateAccountError',
  usernameLogin: 'consumerUsernameLogin',
  usernameLoginError: 'consumerUsernameLoginError',
  setCodeConfirmed: 'consumerSetCodeConfirmed',
  setPhoneNumberToken: 'consumerSetPhoneNumberToken',
  sendCodeError: 'consumerSendCodeError',
  setPhoneNumber: 'consumerSetPhoneNumber',
  performLogin: 'performLogin',
  errorPerformLogin: 'errorPerformLogin',
};

const initialState = {
  isCodeSent: false,
  data: null,
  performLoginData: null,
  allAccounts: null,
  account: null,
  loading: false,
  codeConfirmed: false,
  phoneNumberToken: null,
  errorGetCode: null,
  errorAllAccounts: null,
  errorAccount: null,
  errorCreateAccount: null,
  errorUsernameLogin: null,
  errorPerformLogin: null,
  error: null,
};

export const consumerReducers = (state = initialState, action) => {
  switch (action.type) {
    case CONSUMER_LOGIN_TYPES.getCode:
      return {
        ...state,
        loading: false,
        isCodeSent: true,
        phoneNumber: null,
        errorGetCode: null,
        errorSendCode: null,
        errorAllAccounts: null,
        errorAccount: null,
        errorCreateAccount: null,
        errorUsernameLogin: null,
      };
    case CONSUMER_LOGIN_TYPES.setCodeConfirmed:
      return {
        ...state,
        loading: false,
        codeConfirmed: true,
      };
    case CONSUMER_LOGIN_TYPES.setPhoneNumberToken:
      return {
        ...state,
        phoneNumberToken: action.payload,
      };
    case CONSUMER_LOGIN_TYPES.sendCodeError:
      return {
        ...state,
        loading: false,
        errorSendCode: action.payload,
      };

    case CONSUMER_LOGIN_TYPES.getCodeError:
      return {
        ...state,
        loading: false,
        errorGetCode: action.payload,
      };
    case CONSUMER_LOGIN_TYPES.setPhoneNumber:
      return {
        ...state,
        phoneNumber: action.payload,
      };

    case CONSUMER_LOGIN_TYPES.editPhoneNumber:
      return {
        ...state,
        isCodeSent: false,
      };

    case CONSUMER_LOGIN_TYPES.getAllAccounts:
      return {
        ...state,
        loading: false,
        allAccounts: action.payload,
        errorGetCode: null,
        errorAllAccounts: null,
        errorAccount: null,
        errorCreateAccount: null,
        errorUsernameLogin: null,
      };

    case CONSUMER_LOGIN_TYPES.getAllAccountsError:
      return {
        ...state,
        loading: false,
        errorAllAccounts: action.payload,
      };

    case CONSUMER_LOGIN_TYPES.getAccount:
    case CONSUMER_LOGIN_TYPES.createAccount:
    case CONSUMER_LOGIN_TYPES.usernameLogin:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorGetCode: null,
        errorAllAccounts: null,
        errorAccount: null,
        errorCreateAccount: null,
        errorUsernameLogin: null,
      };

    case CONSUMER_LOGIN_TYPES.getAccountError:
      return {
        ...state,
        loading: false,
        errorAccount: action.payload,
      };

    case CONSUMER_LOGIN_TYPES.createAccountError:
      return {
        ...state,
        loading: false,
        errorCreateAccount: action.payload,
      };

    case CONSUMER_LOGIN_TYPES.usernameLoginError:
      return {
        ...state,
        loading: false,
        errorUsernameLogin: action.payload,
      };

    case CONSUMER_LOGIN_TYPES.setLoadingLogin:
      return {
        ...state,
        loading: true,
      };

    case CONSUMER_LOGIN_TYPES.errorLogin:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CONSUMER_LOGIN_TYPES.performLogin:
      return {
        ...state,
        loading: false,
        performLoginData: action.payload,
        errorPerformLogin: null,
      };
    case CONSUMER_LOGIN_TYPES.errorPerformLogin:
      return {
        ...state,
        loading: false,
        errorPerformLogin: action.payload,
      };

    default:
      return state;
  }
};
