import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import loginImage from 'framework/assets/images/login.svg';
import logo from 'framework/assets/images/logo-uni.svg';
import { useMediaQuery } from 'react-responsive';
import Logo from './Logo';
import clsx from 'clsx';
import '../assets/styles/login.scss';

function Layout({ children }) {
  const isMobile = useMediaQuery({ query: '(max-device-width: 425px)' });

  return (
    <div className={'login-wrapper'}>
      <Logo src={logo} link={true} href={process.env.REACT_APP_MARKETING_URL} />
      <div className={'login-wrapper-layout'}>
        <Container className="px-5">
          <Row className="justify-content-center">
            <Col
              xs={12}
              lg={6}
              xl={5}
              className={clsx(
                'd-flex justify-content-center align-items-center',
                {
                  'py-5': isMobile,
                }
              )}
            >
              {children}
            </Col>
            <Col xs={12} lg={6} xl={7} className={'login-wrapper-image'}>
              <img
                src={loginImage}
                className={'login-wrapper-image-responsive'}
                alt="login"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Layout;
