import { v4 as uuidV4 } from 'uuid';
import { dispatch } from './store';
import { ERROR_TYPES } from './reducers';
import { UPDATE_ACCOUNT_TYPES, USER_LOGIN_TYPE } from 'user/state/reducers';
import { request } from '../helpers/request';

export function errorCreate(message) {
  let id = uuidV4();
  dispatch({
    type: ERROR_TYPES.create,
    payload: { message, id },
  });

  // remove error from redux
  setTimeout(() => dispatch({ type: ERROR_TYPES.delete, payload: id }), 3000);
}

export const profileGetCode = async (data) => {
  try {
    dispatch({ type: UPDATE_ACCOUNT_TYPES.setLoadingCode });

    await request.post(`v1/auth/profile/phone-number/send-code/`, data);
    dispatch({ type: UPDATE_ACCOUNT_TYPES.getCode });
  } catch (e) {
    dispatch({
      type: UPDATE_ACCOUNT_TYPES.getCodeError,
      payload: e?.response?.data,
    });
  }
};
/**
 *  GET USER PROFILE
 *  @param {string} token - JWT token
 */
export const getUserProfile = async (token) => {
  try {
    dispatch({ type: USER_LOGIN_TYPE.setLoadingProfile });

    const res = await request.get(`v1/auth/profile/`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });

    dispatch({
      type: USER_LOGIN_TYPE.getUserProfile,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: USER_LOGIN_TYPE.errorProfile,
      payload: e?.response?.data,
    });
  }
};
/**
 *  UPDATE USER PROFILE
 *
 *  @param {Object} data {first_name, last_name, email}
 *  @param {string} token - JWT token
 */
export const updateProfile = async (data, token) => {
  try {
    dispatch({ type: USER_LOGIN_TYPE.setLoadingProfile });

    const res = await request.patch(`v1/auth/profile/`, data, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });

    dispatch({
      type: USER_LOGIN_TYPE.updateUserProfile,
      payload: res.data,
    });

    dispatch({
      type: USER_LOGIN_TYPE.setIsUserProfileUpdated,
    });
  } catch (e) {
    dispatch({
      type: USER_LOGIN_TYPE.errorProfile,
      payload: e?.response?.data,
    });
  }
};
