import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cookie from 'js-cookie';
import clsx from 'clsx';
import jwt_decode from 'jwt-decode';
import Layout from 'framework/components/Layout';
import UpdateAccountConfirmCode from 'framework/components/UpdateAccountConfirmCode';
import { convertKeysToCamelCase } from 'framework/helpers/utils';
import ProfileForm from 'framework/components/ProfileForm';
import ConsumerAccounts from '../components/ConsumerAccounts';
import ConsumerConfirmCode from '../components/ConsumerConfirmCode';
import ConsumerLoginWithPhoneNumber from '../components/ConsumerLoginWithPhoneNumber';
import ConsumerLoginWithUserName from '../components/ConsumerLoginWithUserName';
import ConsumerCreateAccount from '../components/ConsumerCreateAccount';
import {
  consumersRegisterCreate,
  consumersFinalCreate,
  consumersSendCodeCreate,
  consumersVerifyCodeCreate,
} from '../state/actions';

function ConsumerLoginFormPage(props) {
  const [shownComponent, setShownComponent] = useState('phoneNumber');
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [loginData, setLoginData] = useState({});
  const [loginType, setLoginType] = useState(); // withConsumerCreateAccount - withConsumerAccounts
  const [phoneNumber, setPhoneNumber] = useState('');

  const userLoginServerData = useSelector((state) => state.consumer.data);
  const isProfileUpdated = useSelector((state) => state.profile.isUpdated);
  const { isCodeConfirmed } = useSelector((state) => state.profile);

  let decodedToken;
  const token = props.history.location.search.slice(7);
  if (token) {
    decodedToken = convertKeysToCamelCase(jwt_decode(token));
  }

  function showConfirm(phoneNumber) {
    setPhoneNumber(phoneNumber);
    setShownComponent('confirm');
  }

  let showPhoneNumber = () => setShownComponent('phoneNumber');

  let showAccounts = () => setShownComponent('accounts');

  let showUserName = () => setShownComponent('userName');

  let showNewAccount = () => {
    setShownComponent('createAccount');
  };

  let showProfileForm = () => {
    setShownComponent('profile');
  };

  let showConfirmProfile = (phoneNumber) => {
    setPhoneNumber(phoneNumber);
    setShownComponent('confirmProfile');
  };

  useEffect(() => {
    if (decodedToken?.phoneNumber) {
      setPhoneNumber(decodedToken.phoneNumber);
    }
  }, [decodedToken]);

  useEffect(() => {
    if (userLoginServerData) {
      setIncompleteFields(userLoginServerData.incomplete_fields);
      if (userLoginServerData.is_profile_complete) {
        window.open(userLoginServerData.redirect_url, '_self');
      } else {
        cookie.set('edx-jwt-cookie', userLoginServerData.token);
        setShownComponent('profile');
      }
    }
  }, [userLoginServerData]);

  useEffect(() => {
    if (isProfileUpdated && !incompleteFields?.includes('phone_number')) {
      if (loginType === 'withConsumerAccounts') consumersFinalCreate(loginData);
      else if (loginType === 'withConsumerCreateAccount')
        consumersRegisterCreate(loginData);
    }
    /* eslint-disable-next-line */
  }, [isProfileUpdated]);

  useEffect(() => {
    if (isCodeConfirmed) {
      if (loginType === 'withConsumerAccounts') consumersFinalCreate(loginData);
      else if (loginType === 'withConsumerCreateAccount')
        consumersRegisterCreate(loginData);
    }
  }, [isCodeConfirmed]);

  if (!token) {
    return (
      <div className={clsx('app', 'bg-red')}>
        <p className={'empty-state text-black'}>
          شما مستقیم نمیتوانید این صفحه را فراخوانی کنید لطفا از طریق درس آپ
          وارد شوید
        </p>
      </div>
    );
  }

  return (
    <Layout>
      {(function () {
        switch (shownComponent) {
          case 'confirm':
            return (
              <ConsumerConfirmCode
                phoneNumber={phoneNumber}
                onSubmitAction={consumersVerifyCodeCreate}
                onSendCode={consumersSendCodeCreate}
                onPrev={showPhoneNumber}
                onNext={showAccounts}
                onShowCreateAccount={showNewAccount}
                token={token}
              />
            );
          case 'confirmProfile':
            return (
              <UpdateAccountConfirmCode
                phoneNumber={phoneNumber}
                isProfilePage={false}
                onPrev={showProfileForm}
              />
            );
          case 'accounts':
            return (
              <ConsumerAccounts
                phoneNumber={phoneNumber}
                onNext={showNewAccount}
                token={token}
                setLoginData={setLoginData}
                setLoginType={setLoginType}
              />
            );
          case 'userName':
            return <ConsumerLoginWithUserName onPrev={showPhoneNumber} />;
          case 'createAccount':
            return (
              <ConsumerCreateAccount
                phoneNumber={phoneNumber}
                onPrev={showAccounts}
                token={token}
                setLoginData={setLoginData}
                setLoginType={setLoginType}
              />
            );
          case 'phoneNumber':
            return (
              <ConsumerLoginWithPhoneNumber
                phoneNumber={phoneNumber}
                onNextShowAccounts={showAccounts}
                onNextCreateAccount={showNewAccount}
                onNextIsNotTrusted={showConfirm}
                loginUsername={showUserName}
                decodedToken={decodedToken}
                token={token}
              />
            );
          case 'profile':
            return (
              <ProfileForm
                token={userLoginServerData?.token}
                showConfirm={showConfirmProfile}
                incompleteFields={incompleteFields}
                isProfilePage={false}
                handleBackBtnClick={showPhoneNumber}
                extraMessage={
                  <h4 className="font-weight-bold heading-title">
                    جهت تکمیل فرآیند، فرم زیر را پر کنید.
                  </h4>
                }
              />
            );
          default:
            return <p>مشکلی رخ داده است</p>;
        }
      })()}
    </Layout>
  );
}

export default ConsumerLoginFormPage;
