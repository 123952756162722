export const ERROR_TYPES = {
  create: 'createError',
  delete: 'deleteError',
};

const initialState = {
  generalErrors: {
    data: [],
  },
};

export const framework = (state = initialState, action) => {
  switch (action.type) {
    case ERROR_TYPES.create:
      return {
        ...state,
        generalErrors: {
          data: [...state.generalErrors.data, action.payload],
        },
      };

    case ERROR_TYPES.delete:
      return {
        ...state,
        generalErrors: {
          data: state.generalErrors.data.filter(
            (item) => item.id !== action.payload
          ),
        },
      };

    default:
      return state;
  }
};
