import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { notify } from 'framework/helpers/utils';
import NotFound from 'standAlone/pages/NotFoundPage';
import ConsumerLoginFormPage from 'consumer/pages/ConsumerLoginFormPage';
import ConsumerWelcomeFormPage from 'consumer/pages/ConsumerWelcomeFormPage';
import LoginFormPage from 'user/pages/LoginFormPage';
import ProfileFormPage from 'user/pages/ProfileFormPage';
import 'framework/assets/styles/App.scss';
import { Toaster } from 'react-hot-toast';

const App = () => {
  const generalErrors = useSelector(
    (state) => state.framework.generalErrors.data
  );

  useEffect(() => {
    if (generalErrors) {
      generalErrors.forEach((item) => {
        notify('error', item.message);
      });
    }
  }, [generalErrors]);

  return (
    <>
      <Switch>
        <Route exact path="/login" component={LoginFormPage} />
        <Route exact path="/profile" component={ProfileFormPage} />
        <Route exact path="/consumer/login" component={ConsumerLoginFormPage} />
        <Route
          exact
          path="/consumer/welcome"
          component={ConsumerWelcomeFormPage}
        />
        <Route exact path="/" component={ConsumerLoginFormPage} />{' '}
        {/* for backward compatibility */}
        <Route name="not-found" component={NotFound} />
      </Switch>
      <Toaster />
    </>
  );
};

export default App;
