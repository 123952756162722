import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import cookie from 'js-cookie';
import { shortenString } from 'framework/helpers/utils';
import ProfileForm from 'framework/components/ProfileForm';
import UpdateAccountConfirmCode from 'framework/components/UpdateAccountConfirmCode';
import { Layout } from 'mp-components';

function ProfileFormPage() {
  const [shownComponent, setShownComponent] = useState('profile');
  const [phoneNumber, setPhoneNumber] = useState('');

  const isProfileUpdated = useSelector((state) => state.profile.isUpdated);
  const { isCodeConfirmed } = useSelector((state) => state.profile);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const nextParam = params.get('next');

  const isLogin = cookie.get('edxloggedin');
  const userInfo = cookie.get('edx-user-info');
  let firstName, lastName, nameStr;
  if (userInfo) {
    /* eslint-disable-next-line */
    let userInfoForName = userInfo.replace(/\\054/g, ',').replace(/\\"/g, '"');

    firstName = JSON.parse(userInfoForName).first_name;
    lastName = JSON.parse(userInfoForName).last_name;
    /* eslint-disable-next-line */
    firstName = decodeURIComponent(
      JSON.parse('"' + firstName.replace(/\"/g, '\\"') + '"')
    );
    /* eslint-disable-next-line */
    lastName = decodeURIComponent(
      JSON.parse('"' + lastName.replace(/\"/g, '\\"') + '"')
    );

    nameStr = shortenString(firstName, 12) + ' ' + shortenString(lastName, 12);
  }

  const showConfirm = (phoneNumber) => {
    setPhoneNumber(phoneNumber);
    setShownComponent('confirm');
  };

  const showProfileForm = () => setShownComponent('profile');

  useEffect(() => {
    if (isProfileUpdated && nextParam && isCodeConfirmed) {
      window.location.replace(nextParam);
    }
  }, [isProfileUpdated, isCodeConfirmed, nextParam]);

  return (
    <Layout
      isLogin={isLogin}
      showHeader={true}
      showSidebar={true}
      showHelper={false}
      showFooter={true}
      profileName={nameStr || null}
      theme={'green'}
    >
      {(function () {
        switch (shownComponent) {
          case 'confirm':
            return (
              <UpdateAccountConfirmCode
                phoneNumber={phoneNumber}
                isProfilePage={true}
                onPrev={showProfileForm}
              />
            );
          case 'profile':
            return <ProfileForm showConfirm={showConfirm} />;
          default:
            return <p>مشکلی رخ داده است</p>;
        }
      })()}
    </Layout>
  );
}

export default ProfileFormPage;
