import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cookie from 'js-cookie';
import UpdateAccountConfirmCode from 'framework/components/UpdateAccountConfirmCode';
import Layout from 'framework/components/Layout';
import ProfileForm from 'framework/components/ProfileForm';
import ConsumerWelcomeComponent from '../components/ConsumerWelcomeComponent';
import { consumersPerformLoginCreate } from '../state/actions';

function ConsumerWelcomeFormPage(props) {
  const [shownComponent, setShownComponent] = useState('welcome');
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');

  const userLoginServerData = useSelector(
    (state) => state.consumer.performLoginData
  );
  const isProfileUpdated = useSelector((state) => state.profile.isUpdated);
  const { isCodeConfirmed } = useSelector((state) => state.profile);

  const location = useLocation().search;
  const params = new URLSearchParams(location);

  let firstName = params.get('first_name');
  let lastName = params.get('last_name');
  let username = params.get('username');

  const userData = {
    ...(firstName && { firstName }),
    ...(lastName && { lastName }),
    username,
  };

  let showConfirmProfile = (phoneNumber) => {
    setPhoneNumber(phoneNumber);
    setShownComponent('confirmProfile');
  };

  let showWelcome = () => setShownComponent('welcome');

  let showProfileForm = () => setShownComponent('profile');

  useEffect(() => {
    if (userLoginServerData) {
      setIncompleteFields(userLoginServerData.incomplete_fields);

      if (userLoginServerData.is_profile_complete) {
        window.open(userLoginServerData.redirect_url, '_self');
      } else {
        cookie.set('edx-jwt-cookie', userLoginServerData.token);
        setShownComponent('profile');
      }
    }
  }, [userLoginServerData]);

  useEffect(() => {
    if (isProfileUpdated && !incompleteFields.includes('phone_number')) {
      consumersPerformLoginCreate({ username: userData.username });
    }
    /* eslint-disable-next-line */
  }, [isProfileUpdated]);

  useEffect(() => {
    if (isCodeConfirmed) {
      consumersPerformLoginCreate({ username: userData.username });
    }
    /* eslint-disable-next-line */
  }, [isCodeConfirmed]);

  return (
    <Layout>
      {(function () {
        switch (shownComponent) {
          case 'confirmProfile':
            return (
              <UpdateAccountConfirmCode
                phoneNumber={phoneNumber}
                isProfilePage={false}
                onPrev={showProfileForm}
              />
            );
          case 'welcome':
            return (
              <ConsumerWelcomeComponent
                userData={userData}
                showProfileForm={showProfileForm}
              />
            );
          case 'profile':
            return (
              <ProfileForm
                token={userLoginServerData?.token}
                showConfirm={showConfirmProfile}
                incompleteFields={incompleteFields}
                isProfilePage={false}
                handleBackBtnClick={showWelcome}
                extraMessage={
                  <h4 className="font-weight-bold heading-title">
                    جهت تکمیل فرآیند، فرم زیر را پر کنید.
                  </h4>
                }
              />
            );
          default:
            return <p>مشکلی رخ داده است</p>;
        }
      })()}
    </Layout>
  );
}

export default ConsumerWelcomeFormPage;
