import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import { Else, If, Then, When } from 'react-if';
import { camelCase } from 'change-case';
import pass from 'framework/assets/images/passs.svg';
import username from 'framework/assets/images/ussername.svg';
import Input from 'framework/components/Input';
import Errors from 'framework/components/Errors';
import { loginWithUsernameCreate } from '../state/actions';
import { USERNAME_OR_EMAIL_REGEX } from 'framework/helpers/constants';

const UserLoginWithIdentifier = (props) => {
  let { onPrev, setLoginData, setLoginType } = props;

  let [passwordType, setPasswordType] = useState('password');
  let { loading, errorUsernameLogin } = useSelector((state) => state.login);

  let { handleSubmit, errors, control, setError } = useForm({
    mode: 'onSubmit',
  });

  const location = useLocation().search;
  const params = new URLSearchParams(location);
  let nextParam = params.get('next');

  function onSubmit(data) {
    let newData = {
      ...(nextParam && { next: nextParam }),
      ...data,
    };

    setLoginData(newData);
    setLoginType('withUserName');

    loginWithUsernameCreate(newData);
  }

  let handleBackBtnClick = () => onPrev();

  useEffect(() => {
    if (errorUsernameLogin && typeof errorGetCode !== 'string') {
      Object.keys(errorUsernameLogin).forEach((item) => {
        if (item !== 'non_field_errors' && item !== 'detail') {
          setError(camelCase(item), {
            type: 'manual',
            message: errorUsernameLogin[item][0],
          });
        }
      });
    }
  }, [errorUsernameLogin, setError]);

  return (
    <div className="cc general-wrapper">
      <div className="mb-5 text-right">
        <h4 className="font-weight-bold heading-title">به درس آپ خوش آمدید!</h4>
        <p>دسترسی آسان به دوره ها و کلی خدمات دیگر</p>
      </div>
      <When condition={errorUsernameLogin?.non_field_errors}>
        <Errors data={errorUsernameLogin?.non_field_errors} />
      </When>
      <Form className="userName-login" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2">
          <Input
            name="identifier"
            type="text"
            placeholder="نام کاربری یا ایمیل"
            icon={username}
            control={control}
            rules={{
              required: 'این فیلد الزامی است',
              pattern: {
                value:
                  /* eslint-disable-next-line */
                  USERNAME_OR_EMAIL_REGEX,
                message: 'نام کاربری یا ایمیل وارد شده صحیح نیست.',
              },
            }}
            error={errors.identifier?.message}
          />
        </div>
        <div className={'userName-login-password'}>
          <Input
            name="password"
            type={passwordType}
            placeholder="رمز عبور"
            icon={pass}
            control={control}
            rules={{
              required: 'این فیلد الزامی است.',
            }}
            error={errors.password?.message}
          />
          <span className={'userName-login-password-icon'}>
            {passwordType === 'password' ? (
              <BsFillEyeFill onClick={() => setPasswordType('text')} />
            ) : (
              <BsFillEyeSlashFill onClick={() => setPasswordType('password')} />
            )}
          </span>
        </div>
        <button
          className={clsx({
            'login-button': true,
            'login-button-disabled': loading,
            'w-100': true,
            'border-0': true,
            'mt-1': true,
            'font-weight-bold': true,
          })}
          variant="primary"
          type="submit"
          disabled={loading}
        >
          <If condition={!loading}>
            <Then>
              <span>ورود</span>
            </Then>
            <Else>
              <Spinner animation="border" variant="light" />
            </Else>
          </If>
        </button>
      </Form>
      <div className="field--footer text-center">
        <p className="field--text" onClick={handleBackBtnClick}>
          ورود با رمز یکبار مصرف
        </p>
      </div>
    </div>
  );
};

export default UserLoginWithIdentifier;
