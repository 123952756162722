import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { Else, If, Then, When } from 'react-if';
import { camelCase } from 'change-case';
import pass from 'framework/assets/images/passs.svg';
import username from 'framework/assets/images/ussername.svg';
import Input from 'framework/components/Input';
import Errors from 'framework/components/Errors';
import { USERNAME_REGEX } from 'framework/helpers/constants';

const ConsumerLoginWithUserName = (props) => {
  const { onPrev } = props;

  let { loading, redirectURL, errorUsernameLogin } = useSelector(
    (state) => state.login
  );

  let { handleSubmit, errors, control, setError } = useForm({
    mode: 'onSubmit',
  });

  function onSubmit(data) {}

  let handleBackBtnClick = () => onPrev();

  useEffect(() => {
    if (redirectURL) {
      window.open(redirectURL, '_self');
    }
  }, [redirectURL]);

  useEffect(() => {
    if (errorUsernameLogin && typeof errorGetCode !== 'string') {
      Object.keys(errorUsernameLogin).forEach((item) => {
        if (item !== 'non_field_errors' && item !== 'detail') {
          setError(camelCase(item), {
            type: 'manual',
            message: errorUsernameLogin[item][0],
          });
        }
      });
    }
  }, [errorUsernameLogin, setError]);

  return (
    <div className="cc general-wrapper">
      <div className="mb-5 text-right">
        <h4 className="font-weight-bold heading-title">به درس آپ خوش آمدید!</h4>
        <p>دسترسی آسان به دوره ها و کلی خدمات دیگر</p>
      </div>
      <When condition={errorUsernameLogin?.non_field_errors}>
        <Errors data={errorUsernameLogin?.non_field_errors} />
      </When>
      <Form className="userName-login" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2">
          <Input
            name="username"
            type="text"
            label=""
            placeholder="نام کاربری"
            icon={username}
            control={control}
            rules={{
              required: 'این فیلد الزامی است',
              pattern: {
                /* eslint-disable-next-line */ value: USERNAME_REGEX,
                message:
                  'نام کاربری باید حداقل 4 کاراکتر، با حروف انگلیسی و بدون فاصله باشد.',
              },
            }}
            error={errors.username?.message}
          />
        </div>
        <div>
          <Input
            name="password"
            type="password"
            label=""
            placeholder="رمز عبور"
            icon={pass}
            control={control}
            rules={{
              required: 'این فیلد الزامی است',
            }}
            error={errors.password?.message}
          />
        </div>
        <button
          className={clsx({
            'login-button': true,
            'login-button-disabled': loading,
            'w-100': true,
            'border-0': true,
            'mt-1': true,
            'font-weight-bold': true,
          })}
          variant="primary"
          type="submit"
          disabled={loading}
        >
          <If condition={!loading}>
            <Then>
              <span>ورود</span>
            </Then>
            <Else>
              <Spinner animation="border" variant="light" />
            </Else>
          </If>
        </button>
      </Form>
      <div className="field--footer text-center">
        <p className="field--text" onClick={handleBackBtnClick}>
          ورود با رمز یکبار مصرف
        </p>
      </div>
    </div>
  );
};

export default ConsumerLoginWithUserName;
