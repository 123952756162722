import { UPDATE_ACCOUNT_TYPES, USER_LOGIN_TYPE } from './reducers';
import { dispatch } from 'framework/state/store';
import { request } from 'framework/helpers/request';

/**
 *  JWT TOKEN TO SET IN HEADERS
 *
 *  TODO this TOKEN should be removed later!
 */

export const loginSendCodeCreate = async (data) => {
  try {
    dispatch({ type: USER_LOGIN_TYPE.setLoadingLogin });

    await request.post(`v1/auth/login/send-code/`, data);
    dispatch({ type: USER_LOGIN_TYPE.getCode });
  } catch (e) {
    dispatch({
      type: USER_LOGIN_TYPE.getCodeError,
      payload: e?.response?.data,
    });
  }
};

export const profileConfirmCodeCreate = async (data) => {
  try {
    dispatch({ type: UPDATE_ACCOUNT_TYPES.setLoadingCode });

    await request.post(`v1/auth/profile/phone-number/confirm-code/`, data);
    dispatch({ type: UPDATE_ACCOUNT_TYPES.setCodeConfirmed });
  } catch (e) {
    dispatch({
      type: UPDATE_ACCOUNT_TYPES.confirmCodeError,
      payload: e?.response?.data,
    });
  }
};

/**
 *
 * TO EDIT PHONE NUMBER, THE FLAG(loginSendCodeCreate) IN REDUCER IS CHANGEDBB
 */
export const editPhoneNumber = () => {
  dispatch({ type: USER_LOGIN_TYPE.editPhoneNumber });
};

export const profileEditPhoneNumber = () => {
  dispatch({ type: UPDATE_ACCOUNT_TYPES.editPhoneNumber });
};

/**
 *
 * TO CHECK CODE SENT
 */
export const loginConfirmCodeCreate = async (data) => {
  try {
    dispatch({ type: USER_LOGIN_TYPE.setLoadingLogin });

    const res = await request.post(`v1/auth/login/confirm-code/`, data);

    dispatch({
      type: USER_LOGIN_TYPE.getAllAccounts,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: USER_LOGIN_TYPE.getAllAccountsError,
      payload: e?.response?.data,
    });
  }
};

/**
 *  SELECT ACCOUNT
 *
 * @param {obj} data {username, phone_number}
 *
 */
export const loginFinalCreate = async (data) => {
  try {
    dispatch({ type: USER_LOGIN_TYPE.setLoadingLogin });

    let res = await request.post(`v1/auth/login/final/`, data, {
      withCredentials: true,
    });

    dispatch({ type: USER_LOGIN_TYPE.getAccount, payload: res.data });
  } catch (e) {
    dispatch({
      type: USER_LOGIN_TYPE.getAccountError,
      payload: e?.response?.data,
    });
  }
};

/**
 *
 * @param {obj} data {username, phone_number, first_name, last_name, password}
 * @returns
 */
export const createAccount = async (data) => {
  try {
    dispatch({ type: USER_LOGIN_TYPE.setLoadingLogin });

    let res = await request.post(`v1/auth/registration/register/`, data, {
      withCredentials: true,
    });
    dispatch({ type: USER_LOGIN_TYPE.createAccount, payload: res.data });
  } catch (e) {
    dispatch({
      type: USER_LOGIN_TYPE.createAccountError,
      payload: e?.response?.data,
    });
  }
};

/**
 * LOGIN BASED ON USERNAME AND PASSWORD
 *
 * @param {obj} data {username, password, next}
 *
 */
export const loginWithUsernameCreate = async (data) => {
  try {
    dispatch({ type: USER_LOGIN_TYPE.setLoadingLogin });

    const res = await request.post(`v1/auth/login/`, data, {
      withCredentials: true,
    });

    dispatch({
      type: USER_LOGIN_TYPE.usernameLogin,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: USER_LOGIN_TYPE.usernameLoginError,
      payload: e?.response?.data,
    });
  }
};
