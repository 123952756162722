import _ from 'lodash';
import toast from 'react-hot-toast';
import React from 'react';

export function isPrimitive(variable) {
  return Object(variable) !== variable;
}

export function convertKeys(variable, callBack) {
  if (isPrimitive(variable)) {
    return variable;
  }

  if (_.isArray(variable)) {
    return variable.map((el) => convertKeys(el, callBack));
  }

  const newObject = {};
  _.forOwn(variable, (value, key) => {
    newObject[callBack(key)] = convertKeys(value, callBack);
  });

  return newObject;
}

export function convertKeysToCamelCase(variable) {
  return convertKeys(variable, _.camelCase);
}

export function convertKeysToSnackCase(variable) {
  return convertKeys(variable, _.snakeCase);
}

export function cleanPhoneNumber(phoneNumber) {
  const inputPhoneLen = Number(phoneNumber.length);

  if (inputPhoneLen >= 10 || inputPhoneLen <= 13) {
    if (inputPhoneLen === 13) {
      if (phoneNumber[0] === '+') {
        return phoneNumber;
      }
    }
  }
  if (inputPhoneLen === 12) {
    if (phoneNumber.slice(0, 2) === '98') {
      return '+' + phoneNumber;
    }
  }
  if (inputPhoneLen === 11) {
    if (phoneNumber[0] === '0') {
      return '+98' + phoneNumber.slice(1, inputPhoneLen);
    }
  }
  if (inputPhoneLen === 10) {
    if (phoneNumber[0] === '9') {
      return '+98' + phoneNumber;
    }
  }
  return null;
}

/**
 * Generate a Shorten string .
 * @param {String} str - The string to modified.
 * @param {Number} numberOfChars - Number of chars to slice
 * @return - Modified string
 */

export const shortenString = (str, numberOfChars) => {
  if (str.length > numberOfChars) return str.slice(0, numberOfChars) + '...';
  else return str;
};

/**
 * Generate a toast.
 * @param {String} msg - Message to display.
 * @param {String} type - type of toast
 * @param {Promise} promise - promise (like fetch data).
 * @return - Toast
 */

export const notify = (type, msg, promise = null) => {
  const config = {
    duration: 3000,
    position: 'top-left',
    className: 'custom-notify',
    id: 'notify',
    ariaProps: {
      role: 'status',
      'aria-live': 'polite',
    },
  };
  switch (type) {
    case 'success':
      toast.success(msg, config);
      break;

    case 'error':
      toast.error(msg, config);
      break;

    case 'loading':
      toast.loading(msg, config);
      break;

    case 'promise':
      toast.promise(
        promise,
        {
          loading: 'در حال بارگزاری',
          success: 'با موفقیت انجام شد.',
          error: 'مشکلی رخ داده است.',
        },
        {
          success: {
            duration: 5000,
            icon: '🔥',
          },
        }
      );
      break;

    case 'dismiss':
      toast.dismiss();
      break;

    default:
      toast(msg, config);
      break;
  }
};

/**
 * Creates a warning notify to either confirm or reject .
 * @param {Function} confirmCallback - The callback function that's executes after clicking yes
 * @param {Array} allAccounts - account's data
 *
 */

export const notifyWarningAccounts = (confirmCallback, allAccounts) => {
  toast(
    (t) => (
      <div>
        <div className="create-user-notify-warning">
          <span>
            شما اکانت‌های زیر را در درس آپ دارید که می‌توانید با آن ها وارد شوید
            اگر می‌خواهید اکانت جدید ایجاد کنید دکمه تأیید را بزنید
          </span>
          <ul className="my-5 text-left">
            {allAccounts.map((item) => (
              <li className="my-2 ml-2 font-weight-bold">{item.username}</li>
            ))}
          </ul>
        </div>
        <div className="notify-warning-btn-wrap">
          <button
            onClick={() => {
              toast.dismiss(t.id);
              confirmCallback();
            }}
            className="login-button w-100 border-0 mt-1 font-weight-bold mx-2"
            variant="primary"
          >
            تأیید
          </button>
          <button
            onClick={() => {
              toast.dismiss(t.id);
            }}
            className="light-button w-100 border-0 mt-1 font-weight-bold mx-2"
            variant="primary"
          >
            انصراف
          </button>
        </div>
      </div>
    ),
    {
      duration: '1000000', // Infinity
      className: 'notify-warning',
      position: 'top-center',
      id: 'notify-warning', // Prevent duplicates
    }
  );
};
