import React from 'react';
import clsx from 'clsx';
import '../assets/styles/errors.scss';

function Errors(props) {
  let { data, alertClass } = props;

  let classes = {
    alert_darsup: true,
    alertClass: Boolean(alertClass),
  };

  return (
    <div className={clsx(classes)}>
      <ul>
        {data.map((item) => {
          return <li className={'text-danger'}>{item}</li>;
        })}
      </ul>
    </div>
  );
}

export default Errors;
