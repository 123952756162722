import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faUser } from '@fortawesome/free-solid-svg-icons';
import { When } from 'react-if';
import 'framework/assets/styles/login.scss';
import Errors from 'framework/components/Errors';
import { consumersFinalCreate } from '../state/actions';
import { notify, notifyWarningAccounts } from 'framework/helpers/utils';
import clsx from 'clsx';
import { Spinner } from 'react-bootstrap';

function ConsumerUserAccounts(props) {
  const { phoneNumber, onNext, token, setLoginType, setLoginData } = props;

  const [currentUsername, setCurrentUsername] = useState();
  const { allAccounts, errorAccount, phoneNumberToken, loading } = useSelector(
    (state) => state.consumer
  );

  function onSelectAccount(data) {
    if (!loading) {
      setCurrentUsername(data);
      notify('loading', '... در حال انتقال');
      let newData = {
        phone_number: phoneNumber,
        username: data,
        promise_token: token,
        ...(phoneNumberToken && {
          verified_phone_number_token: phoneNumberToken,
        }),
      };

      setLoginType('withConsumerAccounts');
      setLoginData(newData);

      consumersFinalCreate(newData);
    }
  }

  const handleClickCreateAccount = () => {
    if (!allAccounts?.length) onNext();
    else notifyWarningAccounts(onNext, allAccounts);
  };

  useEffect(() => {
    if (errorAccount) setCurrentUsername(null);
  }, [errorAccount]);

  useEffect(() => {
    return () => {
      setCurrentUsername(null);
    };
  }, []);

  return (
    <div className={'cc general-wrapper'}>
      <When condition={errorAccount?.non_field_errors}>
        <Errors data={errorAccount?.non_field_errors} />
      </When>
      {allAccounts?.length ? (
        <h6 className={'text-justify mb-5 h5'}>
          شما با اکانت‌های زیر در سامانه درس‌آپ عضو هستید. برای ورود، روی اکانت
          کلیک کنید.
        </h6>
      ) : (
        <h6 className={'text-justify mb-5 h5'}>
          {' '}
          شما اکانتی ندارید لطفا حساب کاربری جدید ایجاد کنید.{' '}
        </h6>
      )}
      <div className="account-list--wrapper">
        {!allAccounts?.length ? (
          <p className={'text-center'}>اکانتی وجود ندارد</p>
        ) : (
          allAccounts?.map(function (item) {
            return (
              <div
                className={clsx('account-list', { loading: loading })}
                onClick={() => onSelectAccount(item?.username)}
              >
                <FontAwesomeIcon
                  className={'account-list-icon'}
                  icon={faUser}
                />
                {!item.first_name ? (
                  <span className={'ml-3'}>{item.username}</span>
                ) : (
                  <>
                    <span className={'ml-3'}>
                      {item.first_name + ' ' + item.last_name}
                    </span>{' '}
                    <span className={'ml-2 mt-1 username'}>
                      {`(${item.username})`}
                    </span>
                  </>
                )}
                {loading && currentUsername === item.username ? (
                  <Spinner
                    className="ml-auto"
                    animation="border"
                    variant="primary"
                  />
                ) : (
                  <FontAwesomeIcon
                    className={'account-list-icon-chevron ml-auto'}
                    icon={faChevronRight}
                  />
                )}
              </div>
            );
          })
        )}
      </div>

      <div className="phoneNumber-wrapper-footer text-center mt-0">
        <p
          className="phoneNumber-wrapper-footer-text"
          onClick={handleClickCreateAccount}
        >
          ساخت حساب کاربری جدید
        </p>
      </div>
    </div>
  );
}

export default ConsumerUserAccounts;
