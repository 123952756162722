import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { When } from 'react-if';
import clsx from 'clsx';

const Input = (props) => {
  const {
    type,
    icon,
    control,
    name,
    error,
    placeholder,
    label,
    rules,
    maxlength,
    classInput,
    classIconBox,
    classIcon,
    classLabel,
    classGroup,
    classWrapper,
    classParent = null,
    onChange,
  } = props;

  return (
    <div
      className={clsx('d-flex flex-column w-100', classParent && classParent)}
    >
      <Form.Group
        className={clsx(
          'mb-0 form-group-custom',
          'field',
          classGroup && classGroup
        )}
      >
        <When condition={label}>
          <Form.Label
            className={clsx(
              'font-weight-bold',
              'field--label',
              classLabel && classLabel
            )}
          >
            {label}
          </Form.Label>
        </When>
        <div
          className={clsx(
            'd-flex',
            'field--wrapper',
            'w-100',
            classWrapper && classWrapper
          )}
        >
          <When condition={icon}>
            <InputGroup.Text
              className={clsx(
                'border-0',
                'field--icon_box',
                classIconBox && classIconBox
              )}
            >
              <img
                className={clsx('field--icon', classIcon && classIcon)}
                src={icon}
                alt=""
              />
            </InputGroup.Text>
          </When>
          <div className="label-wrapper">
            <Controller
              control={control}
              name={name}
              rules={rules ? rules : {}}
              defaultValue=""
              render={({ name, ref, value, onChange: handleChange }) => {
                return (
                  <Form.Control
                    type={type}
                    maxLength={maxlength}
                    placeholder={placeholder}
                    className={clsx(
                      'field--input form-control',
                      classInput && classInput
                    )}
                    onChange={onChange ? onChange : handleChange}
                    ref={ref}
                    name={name}
                    value={value}
                  />
                );
              }}
            />
            <Form.Label>
              <span className={'label-content'}>
                {placeholder}
                {rules?.required && <span className={'pr-1'}>*</span>}
              </span>
            </Form.Label>
          </div>
        </div>
      </Form.Group>
      <Form.Text className="text-danger field-error mb-1">{error}</Form.Text>
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.any,
  control: PropTypes.any,
  name: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.any,
  label: PropTypes.any,
  rules: PropTypes.object,
  classInput: PropTypes.string,
  classIconBox: PropTypes.string,
  classIcon: PropTypes.string,
  classLabel: PropTypes.string,
  classGroup: PropTypes.string,
  classWrapper: PropTypes.string,
  onChange: PropTypes.func,
  maxlength: PropTypes.string,
};

export default Input;
