import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Else, If, Then, When } from 'react-if';
import clsx from 'clsx';
import { Form, Spinner } from 'react-bootstrap';
import Input from './Input';
import cookie from 'js-cookie';
import username from '../assets/images/ussername.svg';
import envelope from '../assets/images/envelope.svg';
import mobileImg from '../assets/images/mobile.svg';
import {
  getUserProfile,
  profileGetCode,
  updateProfile,
} from '../state/actions';
import './ProfileForm.scss';
import { PHONE_NUMBER_REGEX } from '../helpers/constants';

const NAME_REGEX_FA =
  /^[\u0020\u2000-\u200F\u2028-\u202F\u0621-\u0628\u062A-\u063A\u0641-\u0642\u0644-\u0648\u064E-\u0651\u0655\u067E\u0686\u0698\u06A9\u06AF\u06BE\u06CC]+$/;

function ProfileForm(props) {
  let {
    isProfilePage = true,
    handleBackBtnClick,
    extraMessage,
    showConfirm,
    incompleteFields = [],
    token,
  } = props;

  const [phoneNumber, setPhoneNumber] = useState();

  if (!token) token = cookie.get('edx-jwt-cookie');

  let {
    handleSubmit,
    control,
    errors,
    setError,
    reset,
    getValues,
    formState: { dirtyFields },
  } = useForm({});
  const { userData, isCodeSent, loading } = useSelector(
    (state) => state.profile
  );
  const { profile } = useSelector((state) => state);

  const [noneFieldError, setNoneFieldError] = useState([]);

  let location = useLocation();
  let params = new URLSearchParams(location.search);
  let fieldsParam = params.get('fields');
  let nextParam = params.get('next');

  if (fieldsParam) fieldsParam = fieldsParam.split(',');

  if (!incompleteFields?.length) incompleteFields = fieldsParam;

  if (incompleteFields?.length)
    incompleteFields = incompleteFields.map((item) => _.camelCase(item));

  function onSubmit(data) {
    let newData = {
      ...(dirtyFields.firstName && { first_name: data.firstName }),
      ...(dirtyFields.lastName && { last_name: data.lastName }),
      ...(dirtyFields.email && { email: data.email }),
      ...(dirtyFields.phoneNumber && { phone_number: data.phoneNumber }),
    };
    if (
      getValues('phoneNumber') &&
      dirtyFields.phoneNumber &&
      (incompleteFields?.includes('phoneNumber') ||
        fieldsParam?.includes('phoneNumber') ||
        !incompleteFields?.length)
    )
      setPhoneNumber(data.phoneNumber);
    else setPhoneNumber(null);

    updateProfile(newData, token);
  }

  useEffect(() => {
    let noneFieldErrors = [];
    for (const errorName in profile.error) {
      if (errorName === 'non_field_errors' || errorName === 'detail') {
        noneFieldErrors.push(profile.error[errorName]);
      } else {
        setError(errorName, {
          type: 'manual',
          message: profile.error[errorName],
        });
      }
    }
    setNoneFieldError(noneFieldErrors);
  }, [profile.error, setError]);

  useEffect(() => {
    if (token) getUserProfile(token);
  }, [token]);

  useEffect(() => {
    if (userData) {
      let newData = {
        firstName: userData.first_name,
        lastName: userData.last_name,
        email: userData.email,
        phoneNumber: userData.phone_number,
      };
      reset(newData);
    }
  }, [userData, reset]);

  useEffect(() => {
    if (
      profile.isUpdated &&
      nextParam &&
      isProfilePage &&
      (!incompleteFields?.includes('phoneNumber') || incompleteFields?.length)
    ) {
      window.location.replace(nextParam);
    }

    if (profile.isUpdated) {
      if (phoneNumber) profileGetCode({ phone_number: phoneNumber });
    }
  }, [profile.isUpdated]);

  useEffect(() => {
    if (isCodeSent) {
      showConfirm(phoneNumber);
    }
  }, [isCodeSent]);

  return (
    <div
      className={clsx({
        'profile-form--wrapper': isProfilePage,
        'cc general-wrapper': !isProfilePage,
      })}
    >
      <div
        className={clsx({
          container: isProfilePage,
        })}
      >
        <div
          className={clsx({
            row: isProfilePage,
          })}
        >
          <div
            className={clsx({
              'm-auto': true,
              'col-xl-5': isProfilePage,
              'col-lg-6': isProfilePage,
              'col-12': isProfilePage,
            })}
          >
            {profile.isUpdated && (
              <p className={'success-message'}>
                حساب کاربری شما با موفقیت به روز رسانی شد
                {nextParam && !phoneNumber && (
                  <p>(در حال هدایت به صفحه مورد نظر)</p>
                )}
                {loading && <p>(در حال ارسال کد به شماره همراه...)</p>}
              </p>
            )}
            {!!extraMessage && (
              <div className={'mb-4 text-right'}>{extraMessage}</div>
            )}
            {token && noneFieldError && (
              <div className="none-field-errors">
                <ul>
                  {noneFieldError.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            {token ? (
              <Form onSubmit={handleSubmit(onSubmit)}>
                {FIELDS.map((item) => {
                  let conditionalProps = {
                    ...(item.maxLength && { maxLength: item.maxLength }),
                  };
                  return (
                    <If condition={Boolean(incompleteFields?.length) === true}>
                      <Then>
                        <When condition={incompleteFields?.includes(item.name)}>
                          <Input
                            name={item.name}
                            type="text"
                            classInput={item.extraClass}
                            placeholder={item.label}
                            control={control}
                            icon={item.icon}
                            rules={item.rules}
                            error={errors[item.name]?.message}
                            maxlength={item.maxLength}
                            {...conditionalProps}
                          />
                        </When>
                      </Then>
                      <Else>
                        <Input
                          name={item.name}
                          type="text"
                          classInput={item.extraClass}
                          placeholder={item.label}
                          icon={item.icon}
                          control={control}
                          rules={item.rules}
                          maxlength={item.maxLength}
                          error={errors[item.name]?.message}
                          {...conditionalProps}
                        />
                      </Else>
                    </If>
                  );
                })}
                <button
                  className={clsx({
                    'login-button': true,
                    'w-100': true,
                    'border-0': true,
                    'mt-1': true,
                    'font-weight-bold': true,
                  })}
                  variant="primary"
                  type="submit"
                >
                  {profile.loading ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    <span>
                      {!dirtyFields.phoneNumber
                        ? isProfilePage
                          ? 'ویرایش'
                          : 'تکمیل پروفایل و ورود'
                        : 'تأیید شماره موبایل'}
                    </span>
                  )}
                </button>
              </Form>
            ) : (
              <h2 className={'none-field-errors'}>لطفا ابتدا لاگین کنید.</h2>
            )}
          </div>
        </div>
      </div>
      {!isProfilePage && !!handleBackBtnClick && (
        <div className="field--footer text-center">
          <p className="field--text" onClick={handleBackBtnClick}>
            انصراف
          </p>
        </div>
      )}
    </div>
  );
}

const FIELDS = [
  {
    name: 'firstName',
    label: 'نام',
    maxLength: '30',
    rules: {
      pattern: {
        value: NAME_REGEX_FA,
        message: 'لطفا با حروف فارسی وارد کنید.',
      },
      required: 'فیلد نام الزامی است.',
    },
    icon: username,
  },
  {
    name: 'lastName',
    label: 'نام خانوادگی',
    maxLength: '30',
    rules: {
      pattern: {
        value: NAME_REGEX_FA,
        message: 'لطفا با حروف فارسی وارد کنید.',
      },
      required: 'فیلد نام خانوادگی الزامی است.',
    },
    icon: username,
  },
  {
    name: 'email',
    label: 'ایمیل',
    maxLength: '50',
    rules: {
      pattern: {
        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: 'لطفا ایمیل معتبر وارد کنید.',
      },
      required: 'فیلد ایمیل الزامی است.',
    },
    icon: envelope,
    extraClass: 'text-left',
  },
  {
    name: 'phoneNumber',
    label: 'شماره موبایل',
    maxLength: '11',
    rules: {
      pattern: {
        value: PHONE_NUMBER_REGEX,
        message: 'شماره موبایل باید با 0 یا 9 شروع شده و کمتر از 10 رقم نباشد.',
      },
      required: 'این فیلد اجباری است',
    },
    icon: mobileImg,
  },
];

export default ProfileForm;
