import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Else, If, Then } from 'react-if';

function Logo(props) {
  let { src, size, className, link, href } = props;

  let classes = {
    logo: true,
    'logo-sm': size === 'sm',
    'logo-lg': size === 'lg',
    [className]: Boolean(className),
  };

  return (
    <div className={clsx(classes)}>
      <If condition={link}>
        <Then>
          <a href={href}>
            <img src={src} alt="logo" className={'logo--img'} />
          </a>
        </Then>
        <Else>
          <img src={src} alt="logo" className={'logo--img'} />
        </Else>
      </If>
    </div>
  );
}

Logo.propTypes = {
  src: PropTypes.string,
  size: PropTypes.string,
};

export default Logo;
