import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import cookie from 'js-cookie';
import { useSelector } from 'react-redux';
import Layout from 'framework/components/Layout';
import ProfileForm from 'framework/components/ProfileForm';
import UpdateAccountConfirmCode from 'framework/components/UpdateAccountConfirmCode';
import UserAccounts from '../components/UserAccounts';
import UserConfirmCode from '../components/UserConfirmCode';
import UserLoginWithPhoneNumber from '../components/UserLoginWithPhoneNumber';
import UserLoginWithIdentifier from '../components/UserLoginWithIdentifier';
import UserCreateAccount from '../components/UserCreateAccount';
import {
  createAccount,
  loginFinalCreate,
  loginWithUsernameCreate,
} from '../state/actions';

function LoginFormPage(props) {
  const [shownComponent, setShownComponent] = useState('phoneNumber');
  const [loginData, setLoginData] = useState({});
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [loginType, setLoginType] = useState(); // withUserName - withUserCreateAccount - withUserAccounts
  const [phoneNumber, setPhoneNumber] = useState('');

  const userLoginServerData = useSelector((state) => state.login.data);
  const isProfileUpdated = useSelector((state) => state.profile.isUpdated);
  const { isCodeConfirmed } = useSelector((state) => state.profile);

  const location = useLocation().search;
  const params = new URLSearchParams(location);

  let showConfirm = (phoneNumber) => {
    setPhoneNumber(phoneNumber);
    setShownComponent('confirm');
  };

  let showPhoneNumber = () => setShownComponent('phoneNumber');

  let showAccounts = () => setShownComponent('accounts');

  let showUserName = () => setShownComponent('userName');

  let showNewAccount = () => {
    setShownComponent('createAccount');
  };

  let showProfileForm = () => {
    setShownComponent('profile');
  };

  let showConfirmProfile = (phoneNumber) => {
    setPhoneNumber(phoneNumber);
    setShownComponent('confirmProfile');
  };

  useEffect(() => {
    if (params.get('loginByUsername') === 'true') {
      showUserName();
    }
  }, [params]);

  useEffect(() => {
    if (userLoginServerData) {
      setIncompleteFields(userLoginServerData.incomplete_fields);
      if (userLoginServerData.is_profile_complete) {
        window.open(userLoginServerData.redirect_url, '_self');
      } else {
        cookie.set('edx-jwt-cookie', userLoginServerData.token);
        setShownComponent('profile');
      }
    }
  }, [userLoginServerData]);

  useEffect(() => {
    if (isProfileUpdated && !incompleteFields.includes('phone_number')) {
      if (loginType === 'withUserName') loginWithUsernameCreate(loginData);
      else if (loginType === 'withUserAccounts') loginFinalCreate(loginData);
      else if (loginType === 'withUserCreateAccount') createAccount(loginData);
    }
    /* eslint-disable-next-line */
  }, [isProfileUpdated]);

  useEffect(() => {
    if (isCodeConfirmed) {
      if (loginType === 'withUserName') loginWithUsernameCreate(loginData);
      else if (loginType === 'withUserAccounts') loginFinalCreate(loginData);
      else if (loginType === 'withUserCreateAccount') createAccount(loginData);
    }
    /* eslint-disable-next-line */
  }, [isCodeConfirmed]);

  return (
    <Layout>
      {(function () {
        switch (shownComponent) {
          case 'confirm':
            return (
              <UserConfirmCode
                phoneNumber={phoneNumber}
                onPrev={showPhoneNumber}
                onNext={showAccounts}
                onShowCreateAccount={showNewAccount}
              />
            );
          case 'confirmProfile':
            return (
              <UpdateAccountConfirmCode
                phoneNumber={phoneNumber}
                isProfilePage={false}
                onPrev={showProfileForm}
              />
            );
          case 'accounts':
            return (
              <UserAccounts
                setLoginData={setLoginData}
                phoneNumber={phoneNumber}
                setLoginType={setLoginType}
                onNext={showNewAccount}
              />
            );
          case 'userName':
            return (
              <UserLoginWithIdentifier
                setLoginData={setLoginData}
                setLoginType={setLoginType}
                onPrev={showPhoneNumber}
              />
            );
          case 'createAccount':
            return (
              <UserCreateAccount
                setLoginData={setLoginData}
                setLoginType={setLoginType}
                phoneNumber={phoneNumber}
                onPrev={showAccounts}
              />
            );
          case 'phoneNumber':
            return (
              <UserLoginWithPhoneNumber
                phoneNumber={phoneNumber}
                onNext={showConfirm}
                loginUsername={showUserName}
              />
            );
          case 'profile':
            return (
              <ProfileForm
                token={userLoginServerData?.token}
                showConfirm={showConfirmProfile}
                incompleteFields={incompleteFields}
                isProfilePage={false}
                handleBackBtnClick={showPhoneNumber}
                extraMessage={
                  <h4 className="font-weight-bold heading-title">
                    جهت تکمیل فرآیند، فرم زیر را پر کنید.
                  </h4>
                }
              />
            );
          default:
            return <p>مشکلی رخ داده است</p>;
        }
      })()}
    </Layout>
  );
}

export default LoginFormPage;
