import axios from 'axios';
import { convertKeysToSnackCase } from './utils';
import qs from 'qs';
import cookie from 'js-cookie';
import { errorCreate } from '../state/actions';

const notFoundErrorMessage = 'برای درخواست شما نتیجه‌ای یافت نشد.';
const fiveHundredErrorMessage =
  'متاسفانه در این لحظه خطایی رخ داده است، لطفا کمی بعد مجددا تلاش کنید';
const nounStatusErrorMessage =
  'متاسفانه در این لحظه خطایی رخ داده است، لطفا کمی بعد مجددا تلاش کنید';
const authorizationErrorMessage = 'عدم دسترسی';
const tokenExpiresMessage = 'توکن شما منقضی شده است';

axios.defaults.data = {};
axios.defaults.paramsSerializer = (params) =>
  qs.stringify(convertKeysToSnackCase(params));

////////////////////////////////////
/////////// Request API ///////////
//////////////////////////////////

const tokenJWT = cookie.get('edx-jwt-cookie');

export const request = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        ...(Boolean(tokenJWT) && {'Authorization': `JWT ${tokenJWT}`})
    },
});

request.interceptors.request.use(
  (config) => {
    config.data = config.data && convertKeysToSnackCase(config.data);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => response,
  function (error) {
    handleErrors(error);
    return Promise.reject(error);
  }
);

function handleErrors(error) {
  function handleNonFieldErrors(errorData) {
    if (
      errorData?.non_field_errors &&
      Array.isArray(errorData?.non_field_errors)
    ) {
      errorData.non_field_errors.forEach((item) => errorCreate(item));
    } else if (errorData?.non_field_errors) {
      errorCreate(errorData.non_field_errors);
    }
  }

  function handle403Errors(errorData) {
    if (Boolean(errorData.detail)) {
      errorCreate(errorData.detail);
    } else {
      errorCreate(authorizationErrorMessage);
    }
  }

  if (Boolean(error.response)) {
    switch (error.response.status) {
      case 400:
        handleNonFieldErrors(error?.response?.data);
        break;
      case 404:
        errorCreate(notFoundErrorMessage);
        break;
      case 403:
        handle403Errors(error.response.data);
        break;
      case 401:
        errorCreate(tokenExpiresMessage);
        break;
      case 500:
        errorCreate(fiveHundredErrorMessage);
        break;
      default:
        errorCreate(nounStatusErrorMessage);
    }
  }
}
