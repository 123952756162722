export const NAME_REGEX_FA =
  /^[\u0020\u2000-\u200F\u2028-\u202F\u0621-\u0628\u062A-\u063A\u0641-\u0642\u0644-\u0648\u064E-\u0651\u0655\u067E\u0686\u0698\u06A9\u06AF\u06BE\u06CC]+$/;
/* eslint-disable-next-line */
export const USERNAME_REGEX =
  /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{4})[^_.].*[^_.]$/;

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;

export const USERNAME_OR_EMAIL_REGEX =
  /^((?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{4})[^_.].*[^_.]|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i;

export const PHONE_NUMBER_REGEX = /^(09|9)\d{9}$/;
