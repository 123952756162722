import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { Else, If, Then, When } from 'react-if';
import { Form, Spinner } from 'react-bootstrap';
import { digitsArToEn, digitsFaToEn } from '@persian-tools/persian-tools';
import { camelCase } from 'change-case';
import mobileImg from 'framework/assets/images/mobile.svg';
import Input from 'framework/components/Input';
import Errors from 'framework/components/Errors';
import { cleanPhoneNumber } from 'framework/helpers/utils';
import {
  consumersAccountsListCreate,
  consumersSendCodeCreate,
} from '../state/actions';
import { PHONE_NUMBER_REGEX } from 'framework/helpers/constants';

const ConsumerLoginWithPhoneNumber = (props) => {
  let {
    onNextCreateAccount,
    onNextShowAccounts,
    phoneNumber,
    loginUsername,
    decodedToken,
    token,
    onNextIsNotTrusted,
  } = props;

  let { isCodeSent, loading, errorGetCode, allAccounts } = useSelector(
    (state) => state.consumer
  );

  let { handleSubmit, errors, control, setValue, getValues, setError } =
    useForm({
      mode: 'onSubmit',
      defaultValues: {
        phoneNumber,
      },
    });

  let handleChange = (e) => {
    setValue('phoneNumber', digitsFaToEn(digitsArToEn(e.target.value)));
  };

  function onSubmit(data) {
    let newData = {
      phone_number: data.phoneNumber,
      promise_token: token,
    };
    if (decodedToken.trustPhoneNumber) {
      if (
        cleanPhoneNumber(decodedToken.phoneNumber) ===
        cleanPhoneNumber(data.phoneNumber)
      ) {
        consumersAccountsListCreate(token);
      } else {
        consumersSendCodeCreate(newData);
      }
    } else {
      consumersSendCodeCreate(newData);
    }
  }

  useEffect(() => {
    if (isCodeSent) {
      onNextIsNotTrusted(getValues('phoneNumber'));
    }
  }, [isCodeSent]);

  useEffect(() => {
    if (allAccounts) {
      if (allAccounts?.length === 0) {
        onNextCreateAccount();
      } else {
        onNextShowAccounts();
      }
    }
  }, [allAccounts]);

  useEffect(() => {
    if (errorGetCode && typeof errorGetCode !== 'string') {
      Object.keys(errorGetCode).map((item) => {
        if (item !== 'non_field_errors' && item !== 'detail') {
          setError(camelCase(item), {
            type: 'manual',
            message: errorGetCode[item][0],
          });
        }
      });
    }
  }, [errorGetCode]);

  useEffect(() => {
    if (phoneNumber) {
      setValue('phoneNumber', phoneNumber);
    }
  }, [phoneNumber]);

  return (
    <div className="cc general-wrapper">
      <Form className="cc--form" onSubmit={handleSubmit(onSubmit)}>
        <When condition={errorGetCode?.non_field_errors}>
          <Errors data={errorGetCode?.non_field_errors} />
        </When>
        <Label />
        <Input
          classInput="field--input phone-number--input"
          classIcon="phone-number--img"
          classIconBox="phone-number--span"
          name="phoneNumber"
          label=""
          maxlength={'11'}
          classWrapper={'login-phone-number'}
          type="text"
          placeholder="شماره موبایل"
          icon={mobileImg}
          control={control}
          rules={{
            pattern: {
              value: PHONE_NUMBER_REGEX,
              message:
                'شماره موبایل باید با 0 یا 9 شروع شده و کمتر از 10 رقم نباشد.',
            },
            required: 'این فیلد اجباری است.',
          }}
          error={errors.phoneNumber?.message}
          onChange={handleChange}
        />
        <button
          className={clsx({
            'login-button': true,
            'login-button-disabled': loading,
            'w-100': true,
            'border-0': true,
            'mt-1': true,
            'font-weight-bold': true,
          })}
          variant="primary"
          type="submit"
          disabled={loading}
        >
          <If condition={!loading}>
            <Then>
              <span>مرحله بعد</span>
            </Then>
            <Else>
              <Spinner animation="border" variant="light" />
            </Else>
          </If>
        </button>
      </Form>
    </div>
  );
};

function Label() {
  return (
    <div className="text-right">
      <h4 className="font-weight-bold">به درس آپ خوش آمدید!</h4>
      <p className="cc--phone_number">
        شماره موبایل خود را برای{' '}
        <span className={'font-weight-bold'}>ورود</span> یا{' '}
        <span className={'font-weight-bold'}>ثبت‌نام</span> وارد نمایید
      </p>
    </div>
  );
}

export default ConsumerLoginWithPhoneNumber;
