import React, { useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Else, If, Then, When } from 'react-if';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { camelCase } from 'change-case';
import Input from 'framework/components/Input';
import Errors from 'framework/components/Errors';
import {
  USERNAME_REGEX,
  NAME_REGEX_FA,
  PASSWORD_REGEX,
} from 'framework/helpers/constants';
import { consumersRegisterCreate } from '../state/actions';
import { useMediaQuery } from 'react-responsive';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

const ConsumerCreateAccount = (props) => {
  const { phoneNumber, onPrev, token, setLoginData, setLoginType } = props;

  const [showPassInput, setShowPassInput] = useState(false);
  const [passwordType, setPasswordType] = useState('password');

  const { allAccounts, loading, errorCreateAccount, phoneNumberToken } =
    useSelector((state) => state.consumer);

  const { control, handleSubmit, errors, setError } = useForm({
    mode: 'onSubmit',
  });

  const isSmallMobile = useMediaQuery({ query: '(max-device-width: 375px)' });
  const isMobile = useMediaQuery({ query: '(max-device-width: 425px)' });

  function onSubmit(data) {
    let { firstName, lastName, password, username } = data;
    let newData = {
      phone_number: phoneNumber,
      first_name: firstName,
      last_name: lastName,
      password,
      username,
      promise_token: token,
      ...(phoneNumberToken && {
        verified_phone_number_token: phoneNumberToken,
      }),
    };

    setLoginType('withConsumerCreateAccount');
    setLoginData(newData);

    consumersRegisterCreate(newData);
  }

  const handleBackBtnClick = () => onPrev();

  useEffect(() => {
    if (errorCreateAccount && typeof errorGetCode !== 'string') {
      Object.keys(errorCreateAccount).forEach((item) => {
        if (item !== 'non_field_errors' && item !== 'detail') {
          setError(camelCase(item), {
            type: 'manual',
            message: errorCreateAccount[item][0],
          });
        }
      });
    }
  }, [errorCreateAccount, setError]);

  return (
    <div className="cc general-wrapper">
      <Form className="cc--form" onSubmit={handleSubmit(onSubmit)}>
        <When condition={errorCreateAccount?.non_field_errors}>
          <Errors data={errorCreateAccount?.non_field_errors} />
        </When>
        <div
          className={clsx('d-flex justify-content-between mb-5 text-right', {
            'flex-wrap': isMobile,
          })}
        >
          <h4 className="font-weight-bold">ایجاد حساب کاربری</h4>
          <When condition={allAccounts?.length}>
            <span
              className="text-info new-account--back"
              onClick={handleBackBtnClick}
            >
              لیست حساب های کاربری
              <FontAwesomeIcon icon={faArrowLeft} className={'mr-1 mt-1'} />
            </span>
          </When>
        </div>
        <div
          className={clsx('d-flex justify-content-between', {
            'flex-wrap': isSmallMobile,
          })}
        >
          <Input
            name={'firstName'}
            type={'text'}
            placeholder={'نام'}
            control={control}
            classGroup="w-95"
            rules={{
              required: 'این فیلد الزامی است.',
              pattern: {
                value: NAME_REGEX_FA,
                message: 'لطفا با حروف فارسی وارد کنید.',
              },
            }}
            error={errors.firstName?.message}
          />
          <Input
            name={'lastName'}
            type={'text'}
            placeholder={'نام خانوادگی'}
            control={control}
            classParent="align-items-end"
            classGroup="w-95"
            rules={{
              required: 'این فیلد الزامی است',
              pattern: {
                value: NAME_REGEX_FA,
                message: 'لطفا با حروف فارسی وارد کنید.',
              },
            }}
            error={errors.lastName?.message}
          />
        </div>
        <Input
          name={'username'}
          type={'text'}
          classInput={'accounts--username_input'}
          placeholder={'نام کاربری'}
          control={control}
          rules={{
            required: 'این فیلد الزامی است.',
            pattern: {
              /* eslint-disable-next-line */ value: USERNAME_REGEX,
              message:
                'نام کاربری باید حداقل 4 کاراکتر، با حروف انگلیسی و بدون فاصله باشد.',
            },
          }}
          error={errors.username?.message}
        />
        <div className="d-flex mb-2">
          <input
            type="checkbox"
            id="horns"
            onClick={() => {
              setShowPassInput(!showPassInput);
            }}
          />
          <label
            className="mr-2 mt-auto mb-auto"
            style={{ color: '#03447b' }}
            htmlFor="horns"
          >
            می خواهم رمز عبور انتخاب کنم
          </label>
        </div>
        <When condition={showPassInput}>
          <div className={'accounts--pass_container'}>
            <Input
              name={'password'}
              type={passwordType}
              classInput={'accounts--pass_input'}
              placeholder={'رمز عبور'}
              control={control}
              rules={{
                required: 'این فیلد الزامی است.',
                pattern: {
                  value:
                    /* eslint-disable-next-line */
                    PASSWORD_REGEX,
                  message:
                    'پسورد وارد شده باید حداقل 6 کاراکتر و شامل اعداد و حروف انگلیسی باشد.',
                },
              }}
              error={errors.password?.message}
            />
            <span className={'create-account-login-password-icon'}>
              {passwordType === 'password' ? (
                <BsFillEyeFill onClick={() => setPasswordType('text')} />
              ) : (
                <BsFillEyeSlashFill
                  onClick={() => setPasswordType('password')}
                />
              )}
            </span>
          </div>
        </When>
        <button
          className={clsx({
            'login-button': true,
            'login-button-disabled': loading,
            'w-100': true,
            'border-0': true,
            'mt-1': true,
            'font-weight-bold': true,
          })}
          variant="primary"
          type="submit"
          disabled={loading}
        >
          <If condition={!loading}>
            <Then>
              <span>تایید</span>
            </Then>
            <Else>
              <Spinner animation="border" variant="light" />
            </Else>
          </If>
        </button>
      </Form>
    </div>
  );
};

export default ConsumerCreateAccount;
