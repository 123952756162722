import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './root.reducer';

const store = configureStore({
  reducer: rootReducer,
});

export default store;

export const dispatch = store.dispatch;
export const getState = store.getState;
