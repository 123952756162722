// login and register process

export const USER_LOGIN_TYPE = {
  setLoadingLogin: 'UserSetLoadingLogin',
  getCode: 'UserGetCode',
  getCodeError: 'UserGetCodeError',
  editPhoneNumber: 'UserEditPhoneNumber',
  getAllAccounts: 'UserGetAllAccounts',
  getAllAccountsError: 'UserGetAllAccountsError',
  getAccount: 'UserGetAccount',
  getAccountError: 'UserGetAccountError',
  errorLogin: 'UserErrorLogin',
  createAccount: 'UserCreateAccount',
  createAccountError: 'UserCreateAccountError',
  usernameLogin: 'UserUsernameLogin',
  usernameLoginError: 'UserUsernameLoginError',
  getUserProfile: 'UserGetUserProfile',
  updateUserProfile: 'UserUpdateUserProfile',
  setIsUserProfileUpdated: 'UserSetIsUserProfileUpdated',
  errorProfile: 'UserErrorProfile',
  setLoadingProfile: 'UserSetLoadingProfile',
  setRegisterLoading: 'UserSetRegisterLoading',
  token: 'token',
  emailVerify: 'UserEmailVerify',
  phoneVerify: 'UserPhoneVerify',
  redirectRegister: 'UserRedirectRegister',
};

export const UPDATE_ACCOUNT_TYPES = {
  getCode: 'updateAccountGetCode',
  setLoadingCode: 'setLoadingCode',
  getCodeError: 'getCodeError',
  editPhoneNumber: 'editPhoneNumber',
  setCodeConfirmed: 'setCodeConfirmed',
  confirmCodeError: 'confirmCodeError',
};

const defaultInitialState = {
  isCodeSent: false,
  data: null,
  allAccounts: null,
  account: null,
  loading: false,
  errorGetCode: null,
  errorAllAccounts: null,
  errorAccount: null,
  errorCreateAccount: null,
  errorUsernameLogin: null,
  error: null,
};
const registerInitialState = {
  token: '',
  emailToken: {
    loading: false,
  },
  phoneToken: {
    loading: false,
  },
  redirectUrl: '',
  loading: false,
};

const profileInitialState = {
  isCodeSent: false,
  isCodeConfirmed: false,
  userData: null,
  loading: false,
  isUpdated: false,
  error: null,
  errorGetCode: null,
  errorConfirmCode: null,
};

export function profileReducer(state = profileInitialState, action) {
  switch (action.type) {
    case USER_LOGIN_TYPE.getUserProfile:
      return {
        ...state,
        userData: action.payload,
        loading: false,
      };

    case USER_LOGIN_TYPE.updateUserProfile:
      return {
        ...state,
        userData: { ...state.userData, ...action.payload },
        loading: false,
      };

    case USER_LOGIN_TYPE.setIsUserProfileUpdated:
      return {
        ...state,
        isUpdated: true,
        loading: false,
      };

    case USER_LOGIN_TYPE.setLoadingProfile:
      return {
        ...state,
        loading: true,
        isUpdated: false,
      };

    case USER_LOGIN_TYPE.errorProfile:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPDATE_ACCOUNT_TYPES.setLoadingCode:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_ACCOUNT_TYPES.getCode:
      return {
        ...state,
        loading: false,
        isCodeSent: true,
        errorGetCode: null,
      };

    case UPDATE_ACCOUNT_TYPES.getCodeError:
      return {
        ...state,
        loading: false,
        errorGetCode: action.payload,
      };

    case UPDATE_ACCOUNT_TYPES.confirmCodeError:
      return {
        ...state,
        loading: false,
        errorConfirmCode: action.payload,
      };

    case UPDATE_ACCOUNT_TYPES.editPhoneNumber:
      return {
        ...state,
        isCodeSent: false,
      };

    case UPDATE_ACCOUNT_TYPES.setCodeConfirmed:
      return {
        ...state,
        isCodeConfirmed: true,
        loading: false,
      };

    default:
      return state;
  }
}

export function loginReducer(state = defaultInitialState, action) {
  switch (action.type) {
    case USER_LOGIN_TYPE.getCode:
      return {
        ...state,
        loading: false,
        isCodeSent: true,
        errorGetCode: null,
        errorAllAccounts: null,
        errorAccount: null,
        errorCreateAccount: null,
        errorUsernameLogin: null,
      };

    case USER_LOGIN_TYPE.getCodeError:
      return {
        ...state,
        loading: false,
        errorGetCode: action.payload,
      };

    case USER_LOGIN_TYPE.editPhoneNumber:
      return {
        ...state,
        isCodeSent: false,
      };

    case USER_LOGIN_TYPE.getAllAccounts:
      return {
        ...state,
        loading: false,
        allAccounts: action.payload,
        errorGetCode: null,
        errorAllAccounts: null,
        errorAccount: null,
        errorCreateAccount: null,
        errorUsernameLogin: null,
      };

    case USER_LOGIN_TYPE.getAllAccountsError:
      return {
        ...state,
        loading: false,
        errorAllAccounts: action.payload,
      };

    case USER_LOGIN_TYPE.getAccount:
    case USER_LOGIN_TYPE.createAccount:
    case USER_LOGIN_TYPE.usernameLogin:
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
        errorGetCode: null,
        errorAllAccounts: null,
        errorAccount: null,
        errorCreateAccount: null,
        errorUsernameLogin: null,
      };

    case USER_LOGIN_TYPE.getAccountError:
      return {
        ...state,
        loading: false,
        errorAccount: action.payload,
      };

    case USER_LOGIN_TYPE.createAccountError:
      return {
        ...state,
        loading: false,
        errorCreateAccount: action.payload,
      };

    case USER_LOGIN_TYPE.usernameLoginError:
      return {
        ...state,
        loading: false,
        errorUsernameLogin: action.payload,
      };

    case USER_LOGIN_TYPE.setLoadingLogin:
      return {
        ...state,
        loading: true,
        errorAccount: null,
      };

    case USER_LOGIN_TYPE.errorLogin:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

export function register(state = registerInitialState, action) {
  switch (action.type) {
    case USER_LOGIN_TYPE.setRegisterLoading:
      return { ...state, loading: action.payload };
    case USER_LOGIN_TYPE.token:
      return { ...state, token: { loading: false, ...action.payload } };
    case USER_LOGIN_TYPE.emailVerify:
      return {
        ...state,
        emailToken: { loading: false, token: action.payload },
      };
    case USER_LOGIN_TYPE.phoneVerify:
      return { ...state, phoneToken: { loading: false, ...action.payload } };
    case USER_LOGIN_TYPE.redirectRegister:
      return { ...state, redirectUrl: action.payload };
    default:
      return state;
  }
}
