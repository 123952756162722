import { combineReducers } from 'redux';
import { consumerReducers } from 'consumer/state/reducers';
import { loginReducer, profileReducer, register } from 'user/state/reducers';
import { framework } from './reducers';

export default combineReducers({
  register,
  consumer: consumerReducers,
  login: loginReducer,
  profile: profileReducer,
  framework: framework,
});
